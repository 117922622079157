.issues-residence-textbox {
  position: absolute;
  top: 30%;
  right: 30%; 
  width: 400px;
  min-height: 100px;
  border-radius: 10px;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.35);
  padding: 30px;
  color: var(--light-grey);
  background-color: var(--creme-white);
  text-align: left;
  border: 5px solid var(--darkest-grey);
  z-index: 2;
}

.issues-residence-wordcloud{
  margin: auto;
  width: 75%;
  padding: 0px;
  z-index: -1;
}

/****** Media Queries ******/
@media (max-width: 1200px) {
  .issues-residence-textbox {
    right: 25%; 
    top: 40%;
  }
}
@media (max-width: 900px) {
  .issues-residence-textbox {
    top: 80%;
    top: 20%;
    right: 17%;
    width: 300px;
  }
  .issues-residence-wordcloud{
    width: 95%;
  }
}

@media (max-width: 600px) {
  .issues-residence-textbox {
    top: 80%;
    top: 20%;
    right: 3%;
    width: 300px;
  }
  .issues-residence-wordcloud{
    width: 95%;
  }
}
