/******** Bar with Boxes ***********/
.bar-with-boxes-root-residence {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 60px 0 100px 0 ;
  padding: 0;
  box-sizing: border-box;
  font-size: 12px;
}


/* the outer container for the whole graph */
.bb-chart-residence {
  display: flex;
  width: 700px;
  padding: 0px 20px;
}


/* the bar containers */
.bb-container-residence {
  display: flex;
  flex-wrap: wrap-reverse;
  margin-left: 0px;
  width: 100px;
  justify-content: center;
  align-content: flex-start;
}


/* each little box */
.box-residence {
  width: 16px;
  height: 12px;
  margin: 1px;
  position: relative;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px; 
  transform: scale(0);
}

.box-residence:hover {
  background-color: white !important;
}

/******** X Axis Text ***********/
.bb-x-axis-residence {
  position: absolute;
  bottom: -25px;
  text-align: start;
  transform: rotate(0deg);
  font-size: 12px;
  color: var(--light-grey);
}

/******** For the info box  ***********/
.bb-info-residence, .bb-info-residence-0, .bb-info-residence-1, .bb-info-residence-2, .bb-info-residence-3 {
  background-color: white;
  color: var(--light-grey);
  padding: 2px;
  border-radius: 5px;
  position: absolute;
  text-align: left;
  /*font-size: 12px;*/

}


/******** For the text that appear on click of box ***********/
.selected-text-residence {
  position: fixed;
  background-color: #ffffffcc;
  bottom: 0;
  width: 60%;
  text-align: center;
  margin: 0 auto 10px;
  padding: 10px;
  overflow: visible;
  border-radius: 2px;
  max-height: 150px;
  overflow-y: auto;
  -ms-overflow-y: auto;
  border-radius: 10px;
}

.selected-text-residence .message{
  text-align: left;
  display: inline-block;
}

.selected-text-residence .legend{
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 1px;
  border-radius: 2px;
  vertical-align: text-bottom;
}

/****** Legend **********/

.legend-container-residence {
  display: flex;
  flex-direction: column;
  left: 30px;
  top: 40px;
  position: absolute;
}
.legend-residence {
  display: flex;
  width: 15px;
  height: 15px;
  margin: 10px;
  margin-top: 0px;
  margin-left: 0px;
  border-radius: 2px; 
}

.legend-text-residence {
  margin-left: 30px;
  margin-top: -3px;
  font-size: 12px;
  white-space: nowrap;
  color: var(--light-grey);
}

/********* Dropdown menu ************/
.currentresidence-dropdown-select {
  color: var(--light-grey);
  background-color: var(--creme-white);
  padding : 15px 10px;
  margin-bottom: 25px;
  opacity: 1;
  border: 10px var(--creme-white);
  border-radius: 10px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  outline: none;
  font-size: 18px;
  width: 100%;
  -webkit-appearance: menulist !important;
}


/******** Media Queries ***********/
@media (max-width: 700px) {
  .bar-with-boxes-root-residence {
    overflow: hidden;
    margin-top: 0px;
  }
  .bb-chart-residence {
    width: 375px;
    margin: 0;
    /*padding: 0;*/
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 15px;
    padding-top: 75px;
    box-sizing: border-box;
  }
  .bb-container-residence {
    margin: 0;
    padding: 0;
    margin-bottom: 67px;
    width: 50px;
    overflow: visible;
  }
  .box-residence {
    width: 8px;
    height: 10px;
  }
  .bb-x-axis-residence {
    transform: rotate(0deg);
    margin: auto;
    text-align: left;
    top: 592px;
    width: 33px;
  }
  .bb-x-axis-residence-item {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    margin: auto;
  }
  .currentresidence-dropdown-select {
    width: 90%;
    font-size: 16px;
  }
  .selected-text-residence {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .box-residence {
    transform: scale(1);
  }
}
