.missing-people-wordcloud{
  /*max-width: 600px;*/
  /*min-width: 300px;*/
  margin: auto;
  padding: 0px;
  z-index: -1;
}


.container-missing-people-wordcloud {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.letter-cards-missing-people-wordcloud {
  /*
  height: 500px;
  overflow-y: auto;
  z-index: 2;
  */
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2;
  margin-left: 20px;
}

.wordcloud-missing-people-section-title {
  font-size: 23px;
  color: var(--light-grey);
  background-color: var(--creme-white);
  padding: 15px; 
  border-radius: 10px;
  margin-bottom: 30px;
}

@media(max-width: 900px) {
  .letter-cards-missing-people-wordcloud {
    /*margin-top: 70px;*/
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    display: flex;
    height: 200px;
    width: 100vw;
    max-height: 50vh;
    position: fixed;
    bottom: 0;
    margin-left: 0;
  }
}