.nightingale-container {
  margin: auto;
  overflow: hidden;
}

.nightingale-button {
  display: inline-block;
  font-size: 12px;
  width: 120px;
  padding: 3px;
}

text {
  font-size: 12px;
  fill: var(--light-grey);
}
