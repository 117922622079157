.homepage-section-title, .homepage-section-description {
  background-color: var(--creme-white); 
  width: 90%;
  margin: 0;
  text-align: left;
  box-sizing: border-box;
}

.homepage-section-title {
  font-size: 24px;
  padding: 15px;
  color: var(--darkest-grey);
}

.homepage-section-description {
  font-size: 14px;
  padding: 0 15px 15px 15px;
  color: var(--light-grey);
}

.section-homepage {
  position: relative;
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}