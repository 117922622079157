.sentiment-letters-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.thoughts-section {
  display: flex;
  justify-content: space-between;
}


.thoughts-container-overall{
  padding: 30px;
}

.thoughts-container {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 60px;
  padding: 50px;
}

.positive {
  background-color: rgba(183,105,53, 0.8);
  border: 3px dashed rgba(183,105,53, 1);
  border-radius: 10px;
}

.negative {
  background-color: rgba(29, 77, 95, 0.8);
  border: 3px dashed rgb(29, 77, 95);
  border-radius: 10px;
}

.letter-card-content {
  font-size: 12px;
}

@media (max-width: 1600px) {
  .sentiment-letters-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 900px) {
  .thoughts-container {
    padding: 20px;
  }
}
@media (max-width: 600px) {
  .thoughts-container {
    padding: 0px;
  }
}
  

