/* For the letter cards container */
.container-family-section {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.topic-boxes {
  height: 600px;
  width: 500px;
  z-index: 1;
}

.letter-cards {
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2;
  margin-left: 20px;
}


/* 
since there a no-wrap to prevent text from going 
to new line in the selection span, best to make it smaller 
on smaller screen so it still stays together
*/
@media (max-width: 600px) {
  .topic-boxes {
    width: 330px;
    padding-right: 0;
  }
}

@media (max-width: 838px) {
  .container-family-section{
    margin-bottom: 50px;
  }
  .letter-cards {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    display: flex;
    height: 200px;
    width: 100vw;
    max-height: 50vh;
    position: fixed;
    bottom: 0;
    margin-left: 0;
  }
}