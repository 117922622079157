@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&family=Noto+Serif+JP:wght@400;500&display=swap');

/*
Colors
dark purple colour used in background: #363644;
off-white color used in all text of css: #f6f2e7;
*/
:root {
  --darkest-purple: #121217;
  --mid-purple: #1b1b22;
  --light-purple: #363644;
  --creme-white: #f6f2e7;
  --darkest-grey: #242423;
  --mid-grey: #353535;
  --light-grey: #565050;
}

/******** BODY ********/
*, body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

body {
  background: url("assets/paper5.png") center / auto repeat;
  color: var(--light-grey);
  /*font-family: 'Noto Serif JP', serif;*/
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

svg {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

ul {
  margin-left: 2%; 
}

/******** TEXT *******/

h1 {
  font-size: 90px;
  margin-top: 100px;
  margin-bottom: 20px;
}

h2 {
  font-size: 40px;
}

h3 {
  color: var(--mid-grey);
  font-weight: normal;
}

.section-title {
  font-size: 32px;
  font-family: 'Noto Serif JP', serif;
  margin-top: 0;
  margin-bottom: 25px;
  width: 100%;
}

p, div {
  font-size: 14px;
}


.response {
  text-align: center;
  margin: auto;
  width: 70%;
  margin-top: 20px;
}

/***** Sections dividing the content *****/

.section {
  box-sizing: border-box;
  position: relative;
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  margin: auto;
  padding-bottom: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.section-divider {
  border: 0;
  height: 3px;
  background-image: linear-gradient(to right, rgba(54,54, 68, 0), rgba(23,86,118, 0.8), rgba(54,54, 68, 0));
  width: 60%;
  margin-top: 60px;
  margin-bottom: 0;
}

/****** Arrows *********/

.back-arrow, .forward-arrow{
  font-size: 40px;
  position: absolute;
  top: 400px;
}

.back-arrow {
  left: 0px;
}

.forward-arrow {
  right: 0px;
}

a {
  color: var(--creme-white);
}

/****** Textboxes ******/

.fancy-textbox {
  border-radius: 0px;
  /*box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.1);*/
  padding: 10px 15px;
  margin-bottom: 25px;
  color: var(--light-grey);
  background-color: var(--creme-white);
  max-width: 700px;
  text-align: center;
  font-size: 18px;
  border-radius: 10px;
}

.explanation-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* for the graph explanation */
.normal-textbox {
  margin: auto;
  margin-bottom: 25px;
  position: relative;
  color: var(--creme-white);
  background: var(--darkest-grey);
  border: 5px solid var(--creme-white);
  width: 60%;
  padding: 30px;
  text-align: left;
  border-radius: 10px;
  z-index: 2;
}

/* for the big expanding text box over the graph */
.explanation-textbox {
  margin: auto;
  position: absolute;
  background: var(--darkest-purple);
  margin-top: 30px;
  text-align: center;
  width: 60%;
  height: 90vh;
  top: 27%;
  padding: 65px;
  text-align: left;
  border: 2px dotted var(--light-purple);
  border-radius: 10px;
  z-index: 2;
  box-sizing: border-box;
  background: 
    center / auto repeat url("assets/linedpaper.png"), 
    linear-gradient(var(--creme-white), var(--creme-white));
  background-blend-mode: multiply;
  color: black;
  border: 4px solid var(--creme-white);
}

/****** Buttons ******/ 

/*
button {
  display: block;
  margin: auto;
  width: 90px;
  margin: 1%;
  line-height: 30px;
  padding: 1%;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: var(--creme-white);
  background-color: var(--light-grey);
  opacity: 0.6;
  border-radius: 10px;
}
button:hover {
  opacity: 1;
  border: 2px solid var(--mid-grey)
}
button:focus{
  outline: none;
}
*/

.graph-close {
  box-sizing: border-box;
  text-align: left;
  font-size: 20px;
  padding: 4px 5px 5px 10px ;
  margin-left: 90vw;
  width: 10vw;
  margin-top: 10px;
  margin-bottom: 0;
  height: 30px;
  border-radius: 5px;
  color: var(--light-grey);
  background-color: var(--creme-white);
  border: none;
  outline: none;
}

.graph-explain-icon {
  margin-bottom: 25px;
  padding: 1px;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  color: var(--light-grey);
  background-color: var(--creme-white);
  border: none;
  outline: none;
}



.graph-explain-icon:hover, .graph-close:hover {
  color: #175676;
}


/****** Footer ******/
#footer {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--creme-white);
  margin-top: 50px;
  height: 50px;
  color: var(--darkest-purple);
}

/****** Media Queries ******/
@media (min-width: 1600px) {
  .section {
    width: 70%
  }
}

@media (max-width: 1200px) {
  .section {
    width: 90%
  }
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 35px;
  }
}
@media (max-width: 900px) {
  .section {
    width: 93%
  }
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 32px;
  }
  .graph-title {
    width: 80%;
    font-size: 16px;
  }
  .normal-textbox {
  }
  .graph-explain-icon {
    right: 0px;
  }
  .graph-close {
    right: 0px;
    top: 0px;
  }
  .back-arrow, .forward-arrow{
    font-size: 40px;
    position: absolute;
    top: 50px;
  }
}

@media (max-width: 600px) {
  .fancy-textbox {
    width: 90%;
    font-size: 16px;
  }
  .graph-explain-icon {
    margin-right: 0;
  }
  .normal-textbox {
    width: 80%;
  }
  .graph-close {
    margin-bottom: 10px;
    margin-left: 85vw;
    width: 15vw;
  }
  .section-title {
    font-size: 21px;
    width: 85%;
  }
}

/* for ipad */
/*
@media (max-width: 1024px), (min-height: 1000px) {
  .section {
    height: 80vh;
  }
  .section-missingpeople {
    height: 120vh !important;
  }
}
*/