
/******** Bar with Boxes ***********/
.bar-with-boxes-root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 60px 0 100px 0;
  padding: 0;
  box-sizing: border-box;
}

/* the outer container for the whole graph */
.bb-chart {
  display: flex;
  width: 600px;
  padding: 0px 20px;
}

/* the bar containers */
.bb-container {
  display: flex;
  flex-wrap: wrap-reverse;
  width: 100px;
  justify-content: center;
  align-content: flex-start;
}

/* each little box */
.box {
  width: 16px;
  height: 12px;
  margin: 1px;
  z-index: 0;
  position: relative;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px; 
  transform: scale(0);
}

.box:hover {
  background-color: white !important;
}

/******** X Axis Text ***********/
.bb-x-axis-peoplementions {
  font-size: 12px;
  position: absolute;
  bottom: -25px;
  text-align: center;
  color: var(--light-grey);
}

/******** Legend - optional ***********/
/*
.legend-container {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-top: 40px;
}
.legend {
  display: flex;
  width: 15px;
  height: 15px;
  margin: 10px;
  margin-top: 0px;
  margin-left: 0px;
  border-radius: 2px; 
}
.legend-text {
  margin-left: 30px;
  margin-top: -3px;
  white-space: nowrap;
}
*/

/******** For the text that appear on click of box ***********/
.selected-text-people {
  position: fixed;
  background-color: #ffffffcc;
  bottom: 0;
  width: 60%;
  text-align: center;
  margin: 0 auto 10px;
  padding: 10px;
  overflow: visible;
  border-radius: 2px;
  max-height: 150px;
  overflow-y: auto;
  -ms-overflow-y: auto;
  border-radius: 10px;
}

.selected-text-people .message{
  text-align: left;
  display: inline-block;
}

/******** For the info box  ***********/
.bb-info-peoplementions {
  background-color: white;
  color: var(--light-grey);
  padding: 2px;
  border-radius: 5px;
  position: absolute;
  font-size: 12px;
  text-align: center
}



/******** Media Queries ***********/
@media (max-width: 600px) {
  .bar-with-boxes-root {
    overflow: hidden;
    margin-top: 0;
  }
  .bb-chart {
    width: 360px;
    margin: 0;
    /*padding: 0;*/
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 15px;
    padding-top: 75px;
    box-sizing: border-box;
  }
  .bb-container {
    margin: 0;
    padding-bottom: 90px;
    width: 50px;
    overflow: visible;
    box-sizing: border-box;
  }
  .box {
    width: 8px;
    height: 10px;
  }
  .bb-x-axis-peoplementions {
    transform: rotate(0deg);
    margin: auto;
    text-align: left;
    top: 650px;
    width: 33px;
  }
  .bb-x-axis-peoplementions-item {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    margin: auto;
  }
  .selected-text-people {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .box {
    transform: scale(1);
  }
}