.letter-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  min-height: 100px;
  max-height: 700px;
  border-radius: 10px;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding: 20px 20px 35px 20px;
  color: var(--light-grey);
  background-color: var(--creme-white);
  text-align: left;
}

.letter-card-small-thoughts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  min-height: 100px;
  max-height: 700px;
  border-radius: 10px;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding: 25px;
  color: var(--light-grey);
  background-color: var(--creme-white);
  text-align: left;
  box-sizing: border-box;
}

.letter-card-small-familyinfluence, .letter-card-small-missingpeople-wordlcoud {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  min-height: 100px;
  max-height: 1000px;
  border-radius: 10px;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  margin-right: 10px;
  padding: 15px 15px 20px 15px;
  color: var(--light-grey);
  background-color: var(--creme-white);
  text-align: left;
  box-sizing: border-box;
}

.letter-card-heading {
  color: var(--light-grey);
  text-align: center;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

.letter-divider {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  width: 200px;
  margin-bottom: 15px;
}

/** for the face photos **/
.letter-card-name-and-photo {
}
.letter-card-heading {
  float: left;
  margin: 5px;
  margin-top: 10px;
}
.avatar-photo-container {
  float: right;
  margin: 1px;
}
.avatar-photo {
  width: 35px;
  border: 3px solid white;
  border-radius: 100%;
}

@media (max-width: 838px){
  .letter-card-small-familyinfluence{
    scroll-snap-align: center;
    width: 80%;
    flex: none;
    background-color: #F6F2E7cc;
  }
  .letter-card-small-familyinfluence p{
    overflow-y: auto;
  }
}

@media (max-width: 900px){
  .letter-card-small-missingpeople-wordlcoud{
    scroll-snap-align: center;
    width: 80%;
    flex: none;
  }
  .letter-card-small-missingpeople-wordlcoud p{
    overflow-y: auto;
  }
}