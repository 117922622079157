#header-section {
  text-align: center;
  margin: auto;
  padding: 7vh 7vh 0;
  height: 100vh;
  background: url("assets/background-1-14x14.png") center / cover no-repeat, url("assets/paper5.png") center / auto repeat;
  background-size: cover, auto;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.header-title-image {
  background-image: url("assets/title.png"); /* The image used */
  height: 75vh; /* You must set a specified height */
  width: 100%;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
}

.header-section-header-text {
  color: var(--darkest-grey);
  font-weight: normal;
}


.header-section-header-text {
  font-size: 100px;
}

.header-section-text {
  padding-top: 3vh;
  font-size: 35px;
  color: var(--darkest-grey);
  opacity: 0.8;
}

.header-arrow-down {
  top: 85%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 50%;
  font-size: 30px;
  color: black;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}


@media (max-width: 1200px) {
  .header-section-header-text {
    font-size: 50px;
  }
}

@media (max-width: 900px) {
  .header-section-header-text {
    font-size: 50px;
  }
}

@media (max-width: 600px) {
  .header-arrow-down {
    bottom: 20%;
  }
}

@media (min-height: 700px) {
  .header-arrow-down {
    top: 85%;
  }
}
