.stream-text, .pie-text {
  font-size: 12px;
}
.pie-path-middle-text {
  font-size: 14px;
}

/****** Media Queries ******/

@media (max-width: 900px) {
  .stream-text, .pie-text  {
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .stream-text, .pie-text  {
    font-size: 12px;
  }
}
