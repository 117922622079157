.pie-text {
  font-size: 12px;
}
.pie-path-middle-text {
  font-size: 14px;
}

.pie-charts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.pie-charts-item {
}

/****** Media Queries ******/

@media (max-width: 900px) {
  .pie-text  {
    font-size: 12px;
  }
  .pie-charts-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .pie-text  {
    font-size: 12px;
  }
  .pie-charts-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}