@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&family=Noto+Serif+JP:wght@400;500&display=swap);
/*
Colors
dark purple colour used in background: #363644;
off-white color used in all text of css: #f6f2e7;
*/
:root {
  --darkest-purple: #121217;
  --mid-purple: #1b1b22;
  --light-purple: #363644;
  --creme-white: #f6f2e7;
  --darkest-grey: #242423;
  --mid-grey: #353535;
  --light-grey: #565050;
}

/******** BODY ********/
*, body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

body {
  background: url(../../static/media/paper5.f395e0a2.png) center / auto repeat;
  color: #565050;
  color: var(--light-grey);
  /*font-family: 'Noto Serif JP', serif;*/
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

svg {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

ul {
  margin-left: 2%; 
}

/******** TEXT *******/

h1 {
  font-size: 90px;
  margin-top: 100px;
  margin-bottom: 20px;
}

h2 {
  font-size: 40px;
}

h3 {
  color: #353535;
  color: var(--mid-grey);
  font-weight: normal;
}

.section-title {
  font-size: 32px;
  font-family: 'Noto Serif JP', serif;
  margin-top: 0;
  margin-bottom: 25px;
  width: 100%;
}

p, div {
  font-size: 14px;
}


.response {
  text-align: center;
  margin: auto;
  width: 70%;
  margin-top: 20px;
}

/***** Sections dividing the content *****/

.section {
  box-sizing: border-box;
  position: relative;
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  margin: auto;
  padding-bottom: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.section-divider {
  border: 0;
  height: 3px;
  background-image: linear-gradient(to right, rgba(54,54, 68, 0), rgba(23,86,118, 0.8), rgba(54,54, 68, 0));
  width: 60%;
  margin-top: 60px;
  margin-bottom: 0;
}

/****** Arrows *********/

.back-arrow, .forward-arrow{
  font-size: 40px;
  position: absolute;
  top: 400px;
}

.back-arrow {
  left: 0px;
}

.forward-arrow {
  right: 0px;
}

a {
  color: #f6f2e7;
  color: var(--creme-white);
}

/****** Textboxes ******/

.fancy-textbox {
  border-radius: 0px;
  /*box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.1);*/
  padding: 10px 15px;
  margin-bottom: 25px;
  color: #565050;
  color: var(--light-grey);
  background-color: #f6f2e7;
  background-color: var(--creme-white);
  max-width: 700px;
  text-align: center;
  font-size: 18px;
  border-radius: 10px;
}

.explanation-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* for the graph explanation */
.normal-textbox {
  margin: auto;
  margin-bottom: 25px;
  position: relative;
  color: #f6f2e7;
  color: var(--creme-white);
  background: #242423;
  background: var(--darkest-grey);
  border: 5px solid #f6f2e7;
  border: 5px solid var(--creme-white);
  width: 60%;
  padding: 30px;
  text-align: left;
  border-radius: 10px;
  z-index: 2;
}

/* for the big expanding text box over the graph */
.explanation-textbox {
  margin: auto;
  position: absolute;
  background: #121217;
  background: var(--darkest-purple);
  margin-top: 30px;
  text-align: center;
  width: 60%;
  height: 90vh;
  top: 27%;
  padding: 65px;
  text-align: left;
  border: 2px dotted #363644;
  border: 2px dotted var(--light-purple);
  border-radius: 10px;
  z-index: 2;
  box-sizing: border-box;
  background: 
    center / auto repeat url(../../static/media/linedpaper.979d4ff4.png), 
    linear-gradient(#f6f2e7, #f6f2e7);
  background: 
    center / auto repeat url(../../static/media/linedpaper.979d4ff4.png), 
    linear-gradient(var(--creme-white), var(--creme-white));
  background-blend-mode: multiply;
  color: black;
  border: 4px solid #f6f2e7;
  border: 4px solid var(--creme-white);
}

/****** Buttons ******/ 

/*
button {
  display: block;
  margin: auto;
  width: 90px;
  margin: 1%;
  line-height: 30px;
  padding: 1%;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: var(--creme-white);
  background-color: var(--light-grey);
  opacity: 0.6;
  border-radius: 10px;
}
button:hover {
  opacity: 1;
  border: 2px solid var(--mid-grey)
}
button:focus{
  outline: none;
}
*/

.graph-close {
  box-sizing: border-box;
  text-align: left;
  font-size: 20px;
  padding: 4px 5px 5px 10px ;
  margin-left: 90vw;
  width: 10vw;
  margin-top: 10px;
  margin-bottom: 0;
  height: 30px;
  border-radius: 5px;
  color: #565050;
  color: var(--light-grey);
  background-color: #f6f2e7;
  background-color: var(--creme-white);
  border: none;
  outline: none;
}

.graph-explain-icon {
  margin-bottom: 25px;
  padding: 1px;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  color: #565050;
  color: var(--light-grey);
  background-color: #f6f2e7;
  background-color: var(--creme-white);
  border: none;
  outline: none;
}



.graph-explain-icon:hover, .graph-close:hover {
  color: #175676;
}


/****** Footer ******/
#footer {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f6f2e7;
  background-color: var(--creme-white);
  margin-top: 50px;
  height: 50px;
  color: #121217;
  color: var(--darkest-purple);
}

/****** Media Queries ******/
@media (min-width: 1600px) {
  .section {
    width: 70%
  }
}

@media (max-width: 1200px) {
  .section {
    width: 90%
  }
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 35px;
  }
}
@media (max-width: 900px) {
  .section {
    width: 93%
  }
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 32px;
  }
  .graph-title {
    width: 80%;
    font-size: 16px;
  }
  .normal-textbox {
  }
  .graph-explain-icon {
    right: 0px;
  }
  .graph-close {
    right: 0px;
    top: 0px;
  }
  .back-arrow, .forward-arrow{
    font-size: 40px;
    position: absolute;
    top: 50px;
  }
}

@media (max-width: 600px) {
  .fancy-textbox {
    width: 90%;
    font-size: 16px;
  }
  .graph-explain-icon {
    margin-right: 0;
  }
  .normal-textbox {
    width: 80%;
  }
  .graph-close {
    margin-bottom: 10px;
    margin-left: 85vw;
    width: 15vw;
  }
  .section-title {
    font-size: 21px;
    width: 85%;
  }
}

/* for ipad */
/*
@media (max-width: 1024px), (min-height: 1000px) {
  .section {
    height: 80vh;
  }
  .section-missingpeople {
    height: 120vh !important;
  }
}
*/
#header-section {
  text-align: center;
  margin: auto;
  padding: 7vh 7vh 0;
  height: 100vh;
  background: url(../../static/media/background-1-14x14.0e254ab4.png) center / cover no-repeat, url(../../static/media/paper5.f395e0a2.png) center / auto repeat;
  background-size: cover, auto;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.header-title-image {
  background-image: url(../../static/media/title.51bded78.png); /* The image used */
  height: 75vh; /* You must set a specified height */
  width: 100%;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
}

.header-section-header-text {
  color: var(--darkest-grey);
  font-weight: normal;
}


.header-section-header-text {
  font-size: 100px;
}

.header-section-text {
  padding-top: 3vh;
  font-size: 35px;
  color: var(--darkest-grey);
  opacity: 0.8;
}

.header-arrow-down {
  top: 85%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 50%;
  font-size: 30px;
  color: black;
}

.bounce {
  animation: bounce 2s infinite;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}


@media (max-width: 1200px) {
  .header-section-header-text {
    font-size: 50px;
  }
}

@media (max-width: 900px) {
  .header-section-header-text {
    font-size: 50px;
  }
}

@media (max-width: 600px) {
  .header-arrow-down {
    bottom: 20%;
  }
}

@media (min-height: 700px) {
  .header-arrow-down {
    top: 85%;
  }
}

.nightingale-container {
  margin: auto;
  overflow: hidden;
}

.nightingale-button {
  display: inline-block;
  font-size: 12px;
  width: 120px;
  padding: 3px;
}

text {
  font-size: 12px;
  fill: var(--light-grey);
}

.issues-residence-textbox {
  position: absolute;
  top: 30%;
  right: 30%; 
  width: 400px;
  min-height: 100px;
  border-radius: 10px;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.35);
  padding: 30px;
  color: var(--light-grey);
  background-color: var(--creme-white);
  text-align: left;
  border: 5px solid var(--darkest-grey);
  z-index: 2;
}

.issues-residence-wordcloud{
  margin: auto;
  width: 75%;
  padding: 0px;
  z-index: -1;
}

/****** Media Queries ******/
@media (max-width: 1200px) {
  .issues-residence-textbox {
    right: 25%; 
    top: 40%;
  }
}
@media (max-width: 900px) {
  .issues-residence-textbox {
    top: 80%;
    top: 20%;
    right: 17%;
    width: 300px;
  }
  .issues-residence-wordcloud{
    width: 95%;
  }
}

@media (max-width: 600px) {
  .issues-residence-textbox {
    top: 80%;
    top: 20%;
    right: 3%;
    width: 300px;
  }
  .issues-residence-wordcloud{
    width: 95%;
  }
}

.stream-text, .pie-text {
  font-size: 12px;
}
.pie-path-middle-text {
  font-size: 14px;
}

/****** Media Queries ******/

@media (max-width: 900px) {
  .stream-text, .pie-text  {
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .stream-text, .pie-text  {
    font-size: 12px;
  }
}

.pie-text {
  font-size: 12px;
}
.pie-path-middle-text {
  font-size: 14px;
}

.pie-charts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;
  gap: 0;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.pie-charts-item {
}

/****** Media Queries ******/

@media (max-width: 900px) {
  .pie-text  {
    font-size: 12px;
  }
  .pie-charts-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .pie-text  {
    font-size: 12px;
  }
  .pie-charts-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.wrapper-survey {
  text-align: center;
  margin: 0 auto 150px;
  position: relative;
}

.survey-sheet, .survey-sheet-pattern {
  rx: 3px;
  ry: 3px;
}

.container-buttons-compalaints {
  display: flex;
}

.prev-person-btn, .next-person-btn {
  box-sizing: border-box;
  width: 60px;
  height: 50px;
  text-align: center;
  margin: auto;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0px;
  border-radius: 10px;
  background-color: var(--creme-white);
  outline: none;
  border: none;
  color: var(--light-grey);
}

.prev-person-btn:hover, .next-person-btn:hover {
  color: var(--creme-white);
  background-color: var(--light-grey);
  border: 2px solid var(--creme-white)
}

.text-move-number {
  font-size: 14px;
}

.survey-title {
  position: absolute;
  top: 110px;
  left: 0px;
  margin: auto;
  text-align: center;
  padding: 15px;
  color: var(--light-grey);
  opacity: 0.9;
  font-weight: bold;
  font-size: 18px;
  border-bottom: 5px solid var(--creme-white);
}

.survey-options-text {
  font-size: 16px;
}

.selected-text-reason {
  position: fixed;
  background-color: #ffffffcc;
  bottom: 0;
  width: 60%;
  text-align: center;
  margin: 0 auto 10px;
  padding: 10px;
  overflow: visible;
  max-height: 150px;
  overflow-y: auto;
  -ms-overflow-y: auto;
  border-radius: 10px;
}

.selected-text-reason .message{
  text-align: left;
  display: inline-block;
}

@media (max-width: 900px) {
  .prev-person-btn, .next-person-btn {
    width: 35px;
    height: 33px;
    padding: 2px;
    box-sizing: border-box;
  }
}

@media (max-width: 600px) {
  .prev-person-btn, .next-person-btn {
    width: 45px;
    height: 45px;
    padding: 2px;
    box-sizing: border-box;
  }
  .prev-person-btn {
    margin-left: 20px;
    margin-right: 8px;
  }
  .next-person-btn {
    margin-left: 8px;
    margin-right: 20px;
  }
  .text-move-number {
    font-size: 12px;
  }
  .survey-title {
    top: 107px;
    padding: 10px;
    width: 250px;
  }
  .survey-options-text {
    font-size: 14px;
  }
  .selected-text-reason{
    width: 90%;
  }
}

@media (max-width: 360px) {
  .prev-person-btn, .next-person-btn {
    width: 35px;
    height: 33px;
    padding: 2px;
    box-sizing: border-box;
  }
}

.letter-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  min-height: 100px;
  max-height: 700px;
  border-radius: 10px;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding: 20px 20px 35px 20px;
  color: var(--light-grey);
  background-color: var(--creme-white);
  text-align: left;
}

.letter-card-small-thoughts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  min-height: 100px;
  max-height: 700px;
  border-radius: 10px;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding: 25px;
  color: var(--light-grey);
  background-color: var(--creme-white);
  text-align: left;
  box-sizing: border-box;
}

.letter-card-small-familyinfluence, .letter-card-small-missingpeople-wordlcoud {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  min-height: 100px;
  max-height: 1000px;
  border-radius: 10px;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  margin-right: 10px;
  padding: 15px 15px 20px 15px;
  color: var(--light-grey);
  background-color: var(--creme-white);
  text-align: left;
  box-sizing: border-box;
}

.letter-card-heading {
  color: var(--light-grey);
  text-align: center;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

.letter-divider {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  width: 200px;
  margin-bottom: 15px;
}

/** for the face photos **/
.letter-card-name-and-photo {
}
.letter-card-heading {
  float: left;
  margin: 5px;
  margin-top: 10px;
}
.avatar-photo-container {
  float: right;
  margin: 1px;
}
.avatar-photo {
  width: 35px;
  border: 3px solid white;
  border-radius: 100%;
}

@media (max-width: 838px){
  .letter-card-small-familyinfluence{
    scroll-snap-align: center;
    width: 80%;
    flex: none;
    background-color: #F6F2E7cc;
  }
  .letter-card-small-familyinfluence p{
    overflow-y: auto;
  }
}

@media (max-width: 900px){
  .letter-card-small-missingpeople-wordlcoud{
    scroll-snap-align: center;
    width: 80%;
    flex: none;
  }
  .letter-card-small-missingpeople-wordlcoud p{
    overflow-y: auto;
  }
}
.sentiment-letters-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.thoughts-section {
  display: flex;
  justify-content: space-between;
}


.thoughts-container-overall{
  padding: 30px;
}

.thoughts-container {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 60px;
  padding: 50px;
}

.positive {
  background-color: rgba(183,105,53, 0.8);
  border: 3px dashed rgba(183,105,53, 1);
  border-radius: 10px;
}

.negative {
  background-color: rgba(29, 77, 95, 0.8);
  border: 3px dashed rgb(29, 77, 95);
  border-radius: 10px;
}

.letter-card-content {
  font-size: 12px;
}

@media (max-width: 1600px) {
  .sentiment-letters-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 900px) {
  .thoughts-container {
    padding: 20px;
  }
}
@media (max-width: 600px) {
  .thoughts-container {
    padding: 0px;
  }
}
  



/******** Bar with Boxes ***********/
.bar-with-boxes-root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 60px 0 100px 0;
  padding: 0;
  box-sizing: border-box;
}

/* the outer container for the whole graph */
.bb-chart {
  display: flex;
  width: 600px;
  padding: 0px 20px;
}

/* the bar containers */
.bb-container {
  display: flex;
  flex-wrap: wrap-reverse;
  width: 100px;
  justify-content: center;
  align-content: flex-start;
}

/* each little box */
.box {
  width: 16px;
  height: 12px;
  margin: 1px;
  z-index: 0;
  position: relative;
  border-radius: 2px; 
  transform: scale(0);
}

.box:hover {
  background-color: white !important;
}

/******** X Axis Text ***********/
.bb-x-axis-peoplementions {
  font-size: 12px;
  position: absolute;
  bottom: -25px;
  text-align: center;
  color: var(--light-grey);
}

/******** Legend - optional ***********/
/*
.legend-container {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-top: 40px;
}
.legend {
  display: flex;
  width: 15px;
  height: 15px;
  margin: 10px;
  margin-top: 0px;
  margin-left: 0px;
  border-radius: 2px; 
}
.legend-text {
  margin-left: 30px;
  margin-top: -3px;
  white-space: nowrap;
}
*/

/******** For the text that appear on click of box ***********/
.selected-text-people {
  position: fixed;
  background-color: #ffffffcc;
  bottom: 0;
  width: 60%;
  text-align: center;
  margin: 0 auto 10px;
  padding: 10px;
  overflow: visible;
  border-radius: 2px;
  max-height: 150px;
  overflow-y: auto;
  -ms-overflow-y: auto;
  border-radius: 10px;
}

.selected-text-people .message{
  text-align: left;
  display: inline-block;
}

/******** For the info box  ***********/
.bb-info-peoplementions {
  background-color: white;
  color: var(--light-grey);
  padding: 2px;
  border-radius: 5px;
  position: absolute;
  font-size: 12px;
  text-align: center
}



/******** Media Queries ***********/
@media (max-width: 600px) {
  .bar-with-boxes-root {
    overflow: hidden;
    margin-top: 0;
  }
  .bb-chart {
    width: 360px;
    margin: 0;
    /*padding: 0;*/
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 15px;
    padding-top: 75px;
    box-sizing: border-box;
  }
  .bb-container {
    margin: 0;
    padding-bottom: 90px;
    width: 50px;
    overflow: visible;
    box-sizing: border-box;
  }
  .box {
    width: 8px;
    height: 10px;
  }
  .bb-x-axis-peoplementions {
    transform: rotate(0deg);
    margin: auto;
    text-align: left;
    top: 650px;
    width: 33px;
  }
  .bb-x-axis-peoplementions-item {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    margin: auto;
  }
  .selected-text-people {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .box {
    transform: scale(1);
  }
}
/******** Bar with Boxes ***********/
.bar-with-boxes-root-residence {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 60px 0 100px 0 ;
  padding: 0;
  box-sizing: border-box;
  font-size: 12px;
}


/* the outer container for the whole graph */
.bb-chart-residence {
  display: flex;
  width: 700px;
  padding: 0px 20px;
}


/* the bar containers */
.bb-container-residence {
  display: flex;
  flex-wrap: wrap-reverse;
  margin-left: 0px;
  width: 100px;
  justify-content: center;
  align-content: flex-start;
}


/* each little box */
.box-residence {
  width: 16px;
  height: 12px;
  margin: 1px;
  position: relative;
  border-radius: 2px; 
  transform: scale(0);
}

.box-residence:hover {
  background-color: white !important;
}

/******** X Axis Text ***********/
.bb-x-axis-residence {
  position: absolute;
  bottom: -25px;
  text-align: start;
  transform: rotate(0deg);
  font-size: 12px;
  color: var(--light-grey);
}

/******** For the info box  ***********/
.bb-info-residence, .bb-info-residence-0, .bb-info-residence-1, .bb-info-residence-2, .bb-info-residence-3 {
  background-color: white;
  color: var(--light-grey);
  padding: 2px;
  border-radius: 5px;
  position: absolute;
  text-align: left;
  /*font-size: 12px;*/

}


/******** For the text that appear on click of box ***********/
.selected-text-residence {
  position: fixed;
  background-color: #ffffffcc;
  bottom: 0;
  width: 60%;
  text-align: center;
  margin: 0 auto 10px;
  padding: 10px;
  overflow: visible;
  border-radius: 2px;
  max-height: 150px;
  overflow-y: auto;
  -ms-overflow-y: auto;
  border-radius: 10px;
}

.selected-text-residence .message{
  text-align: left;
  display: inline-block;
}

.selected-text-residence .legend{
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 1px;
  border-radius: 2px;
  vertical-align: text-bottom;
}

/****** Legend **********/

.legend-container-residence {
  display: flex;
  flex-direction: column;
  left: 30px;
  top: 40px;
  position: absolute;
}
.legend-residence {
  display: flex;
  width: 15px;
  height: 15px;
  margin: 10px;
  margin-top: 0px;
  margin-left: 0px;
  border-radius: 2px; 
}

.legend-text-residence {
  margin-left: 30px;
  margin-top: -3px;
  font-size: 12px;
  white-space: nowrap;
  color: var(--light-grey);
}

/********* Dropdown menu ************/
.currentresidence-dropdown-select {
  color: var(--light-grey);
  background-color: var(--creme-white);
  padding : 15px 10px;
  margin-bottom: 25px;
  opacity: 1;
  border: 10px var(--creme-white);
  border-radius: 10px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  outline: none;
  font-size: 18px;
  width: 100%;
  -webkit-appearance: menulist !important;
}


/******** Media Queries ***********/
@media (max-width: 700px) {
  .bar-with-boxes-root-residence {
    overflow: hidden;
    margin-top: 0px;
  }
  .bb-chart-residence {
    width: 375px;
    margin: 0;
    /*padding: 0;*/
    padding-left: 55px;
    padding-right: 55px;
    padding-bottom: 15px;
    padding-top: 75px;
    box-sizing: border-box;
  }
  .bb-container-residence {
    margin: 0;
    padding: 0;
    margin-bottom: 67px;
    width: 50px;
    overflow: visible;
  }
  .box-residence {
    width: 8px;
    height: 10px;
  }
  .bb-x-axis-residence {
    transform: rotate(0deg);
    margin: auto;
    text-align: left;
    top: 592px;
    width: 33px;
  }
  .bb-x-axis-residence-item {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    margin: auto;
  }
  .currentresidence-dropdown-select {
    width: 90%;
    font-size: 16px;
  }
  .selected-text-residence {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .box-residence {
    transform: scale(1);
  }
}

/* For the letter cards container */
.container-family-section {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.topic-boxes {
  height: 600px;
  width: 500px;
  z-index: 1;
}

.letter-cards {
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2;
  margin-left: 20px;
}


/* 
since there a no-wrap to prevent text from going 
to new line in the selection span, best to make it smaller 
on smaller screen so it still stays together
*/
@media (max-width: 600px) {
  .topic-boxes {
    width: 330px;
    padding-right: 0;
  }
}

@media (max-width: 838px) {
  .container-family-section{
    margin-bottom: 50px;
  }
  .letter-cards {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
    display: flex;
    height: 200px;
    width: 100vw;
    max-height: 50vh;
    position: fixed;
    bottom: 0;
    margin-left: 0;
  }
}
.missing-people-wordcloud{
  /*max-width: 600px;*/
  /*min-width: 300px;*/
  margin: auto;
  padding: 0px;
  z-index: -1;
}


.container-missing-people-wordcloud {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.letter-cards-missing-people-wordcloud {
  /*
  height: 500px;
  overflow-y: auto;
  z-index: 2;
  */
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2;
  margin-left: 20px;
}

.wordcloud-missing-people-section-title {
  font-size: 23px;
  color: var(--light-grey);
  background-color: var(--creme-white);
  padding: 15px; 
  border-radius: 10px;
  margin-bottom: 30px;
}

@media(max-width: 900px) {
  .letter-cards-missing-people-wordcloud {
    /*margin-top: 70px;*/
    overflow-x: auto;
    overflow-y: hidden;
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
    display: flex;
    height: 200px;
    width: 100vw;
    max-height: 50vh;
    position: fixed;
    bottom: 0;
    margin-left: 0;
  }
}
.homepage-section-title, .homepage-section-description {
  background-color: var(--creme-white); 
  width: 90%;
  margin: 0;
  text-align: left;
  box-sizing: border-box;
}

.homepage-section-title {
  font-size: 24px;
  padding: 15px;
  color: var(--darkest-grey);
}

.homepage-section-description {
  font-size: 14px;
  padding: 0 15px 15px 15px;
  color: var(--light-grey);
}

.section-homepage {
  position: relative;
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}
