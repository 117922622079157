.wrapper-survey {
  text-align: center;
  margin: 0 auto 150px;
  position: relative;
}

.survey-sheet, .survey-sheet-pattern {
  rx: 3px;
  ry: 3px;
}

.container-buttons-compalaints {
  display: flex;
}

.prev-person-btn, .next-person-btn {
  box-sizing: border-box;
  width: 60px;
  height: 50px;
  text-align: center;
  margin: auto;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0px;
  border-radius: 10px;
  background-color: var(--creme-white);
  outline: none;
  border: none;
  color: var(--light-grey);
}

.prev-person-btn:hover, .next-person-btn:hover {
  color: var(--creme-white);
  background-color: var(--light-grey);
  border: 2px solid var(--creme-white)
}

.text-move-number {
  font-size: 14px;
}

.survey-title {
  position: absolute;
  top: 110px;
  left: 0px;
  margin: auto;
  text-align: center;
  padding: 15px;
  color: var(--light-grey);
  opacity: 0.9;
  font-weight: bold;
  font-size: 18px;
  border-bottom: 5px solid var(--creme-white);
}

.survey-options-text {
  font-size: 16px;
}

.selected-text-reason {
  position: fixed;
  background-color: #ffffffcc;
  bottom: 0;
  width: 60%;
  text-align: center;
  margin: 0 auto 10px;
  padding: 10px;
  overflow: visible;
  max-height: 150px;
  overflow-y: auto;
  -ms-overflow-y: auto;
  border-radius: 10px;
}

.selected-text-reason .message{
  text-align: left;
  display: inline-block;
}

@media (max-width: 900px) {
  .prev-person-btn, .next-person-btn {
    width: 35px;
    height: 33px;
    padding: 2px;
    box-sizing: border-box;
  }
}

@media (max-width: 600px) {
  .prev-person-btn, .next-person-btn {
    width: 45px;
    height: 45px;
    padding: 2px;
    box-sizing: border-box;
  }
  .prev-person-btn {
    margin-left: 20px;
    margin-right: 8px;
  }
  .next-person-btn {
    margin-left: 8px;
    margin-right: 20px;
  }
  .text-move-number {
    font-size: 12px;
  }
  .survey-title {
    top: 107px;
    padding: 10px;
    width: 250px;
  }
  .survey-options-text {
    font-size: 14px;
  }
  .selected-text-reason{
    width: 90%;
  }
}

@media (max-width: 360px) {
  .prev-person-btn, .next-person-btn {
    width: 35px;
    height: 33px;
    padding: 2px;
    box-sizing: border-box;
  }
}
